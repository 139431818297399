import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ApiPartnerUsersService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ApiPartnerUsersStoreEffects {
  constructor(
    private dataService: ApiPartnerUsersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ apiPartnerId, page }) =>
        this.dataService.load(apiPartnerId, page).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              apiPartnerUsers: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ apiPartnerId, apiPartnerUserId }) =>
        this.dataService.loadDetails(apiPartnerId, apiPartnerUserId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadDetailsSuccess({
              apiPartnerUser: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            this.router.navigate([
              'admin/api-partners',
              request.api_partner_id,
              'users',
            ]);
            return fromActions.createSuccess({
              apiPartnerUser: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            this.router.navigate([
              'admin/api-partners',
              request.api_partner_id,
              'users',
            ]);
            return fromActions.updateSuccess({
              apiPartnerUser: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ apiPartnerId, apiPartnerUserId }) =>
        this.dataService.delete(apiPartnerId, apiPartnerUserId).pipe(
          map(() => fromActions.deleteSuccess({ apiPartnerUserId })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
