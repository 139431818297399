import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { ApiPartnerUser, SaveApiPartnerUserRequest } from '../../models';

export const loadRequest = createAction(
  '[Api Partner Users] Load Request',
  props<{
    apiPartnerId: number;
    page: number;
  }>(),
);

export const loadSuccess = createAction(
  '[Api Partner Users] Load Success',
  props<{ apiPartnerUsers: ApiPartnerUser[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Api Partner Users] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Api Partner Users] Load Details Request',
  props<{
    apiPartnerId: number;
    apiPartnerUserId: number;
  }>(),
);

export const loadDetailsSuccess = createAction(
  '[Api Partner Users] Load Details Success',
  props<{ apiPartnerUser: ApiPartnerUser }>(),
);

export const loadDetailsFailure = createAction(
  '[Api Partner Users] Load Details Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Api Partner Users] Create Request',
  props<{ request: SaveApiPartnerUserRequest }>(),
);

export const createSuccess = createAction(
  '[Api Partner Users] Create Success',
  props<{
    apiPartnerUser: ApiPartnerUser;
  }>(),
);

export const createFailure = createAction(
  '[Api Partner Users] Create Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Api Parnter Users] Delete Request',
  props<{
    apiPartnerId: number;
    apiPartnerUserId: number;
  }>(),
);

export const deleteSuccess = createAction(
  '[Api Parnter Users] Delete Success',
  props<{
    apiPartnerUserId: number;
  }>(),
);

export const deleteFailure = createAction(
  '[Api Parnter Users] Delete Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Api Partner Users] Update Request',
  props<{ request: SaveApiPartnerUserRequest }>(),
);

export const updateSuccess = createAction(
  '[Api Partner Users] Update Success',
  props<{
    apiPartnerUser: ApiPartnerUser;
  }>(),
);

export const updateFailure = createAction(
  '[Api Partner Users] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Api Partner Users] Reset State');
